.reports-overview {
	.common-box {
		width: 100%;
		overflow: hidden;
		position: relative;
		margin: 0;
	}
	.mb-25 {
		margin-bottom: 25px;
	}
}
.reports-overview-wrap {
	display: flex;
	justify-content: space-between;
	margin: 0 0 30px;
}

.reports-overview-detail {
	h4 {
		margin: 0 0 4px;
		font: normal normal 18px/24px Helvetica;
		letter-spacing: 0.44px;
		color: #1b1c21;
	}
	span {
		display: block;
		font: normal normal 16px/20px Helvetica;
		letter-spacing: 0.25px;
		color: #6d6d71;
	}
}

.reports-overview-icon {
	display: flex;
	svg {
		cursor: pointer;
	}
	span {
		font: normal bold 16px/24px Helvetica;
		color: #37383d;
		letter-spacing: 0.44px;
		span {
			font-weight: 500;
		}
	}
}

.progress-list {
	li {
		margin: 10px 15px 0 0;
		display: inline-block;
		&:last-child {
			margin: 0;
		}
	}
	.progress-circle-dot {
		display: flex;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		font-size: 8px;
		overflow: hidden;
		color: #fff;
		align-items: center;
		justify-content: center;
		padding: 1px;
		&.default-color {
			background: #503795;
			color: #fff;
		}
		&.primary-color {
			background: #855cf8;
			color: #fff;
		}
		&.secondary-color {
			background: #ebe5fe;
			color: #1b1c21;
		}
	}
	.progress-circle-title {
		font: normal normal 14px/16px Helvetica;
		color: #263238;
		letter-spacing: 0.4px;
		text-transform: capitalize;
	}
}

.piechart-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.progress-list {
		flex: 1;
		li {
			display: block;
			margin: 0 0 10px;
			&:last-child {
				margin: 0;
			}
		}
	}
}

.CircularProgressbar {
	width: 100%;
	height: auto;
}

.recharts-wrapper {
	width: 100% !important;
	height: 100% !important;
	flex: 1.4;
	.recharts-surface {
		width: 100%;
		height: 100%;
	}
}

.area-chart-wrap {
	padding-bottom: 60%;
}
.area-line-chart {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
