.update-password-form {
	padding: 0;
	min-height: auto;
	.form-floating {
		.form-control {
			padding-right: 60px;
		}
	}
}

.form-custom-label {
	display: block;
	font: normal normal 18px/21px Helvetica;
	color: #13171f;
	margin: 0 0 20px;
}

.update-password-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 60px 0 0;
}

.password-log-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 36px;
	.password-log-info {
		margin: 0 0 5px;
	}
	.password-log-view {
		margin: 5px 0 0;
	}
}

.password-log-info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.info-wrap {
		margin-left: 24px;
		h4 {
			font: normal normal 18px/21px Helvetica;
			margin: 0 0 10px;
			color: #1b1c21;
		}
		span {
			font: normal normal 16px/18px Helvetica;
			display: block;
			color: #76777a;
		}
	}
}

.password-log-view {
	font: normal normal 16px/18px Helvetica;
	background: #f7f8fa;
	color: #1b1c21;
	display: flex;
	align-items: center;
	padding: 8px 14px;
	border-radius: 16px;
	border: none;
	&:hover {
		color: #6f75bc;
	}
	img {
		width: 25px;
		margin-right: 8px;
	}
}

.enable-password-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.enable-password-info {
	h4 {
		font: normal normal 20px/23px Helvetica;
		color: #13171f;
		margin: 0 0 10px;
	}
	span {
		display: block;
		font: normal normal 16px/18px Helvetica;
		color: #76777a;
	}
}

.enable-password-form {
	margin: 30px 0 0;
	padding: 0;
	min-height: auto;
	.form-floating {
		width: 50%;
		@media (max-width: 1399px) {
			width: 70%;
		}
		@media (max-width: 1023px) {
			width: 100%;
		}
	}
}

.enable-date-text {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0 0;
	font: normal normal 13px/18px Helvetica;
	color: #76777a;
	strong {
		display: block;
		font-weight: 600;
		margin: 0 0 5px;
	}
}

.verify-wrap {
	text-align: center;
	margin: 0;
	h4 {
		margin: 30px;
	}
	.fa {
		font-size: 24px;
		margin: 0 15px 0 0;
		&.fa-check-circle {
			color: #00c376;
		}
		&.fa-times-circle {
			color: #f46582;
		}
	}
}
