.view-log-modal {
  background: rgba(255, 255, 255, .8);
  .btn-close {
    display: none;
    &:focus {
      box-shadow: none;
    }
  }
  .modal-content {
    border-radius: 0.5rem;
    border: .063rem solid #eaebef;
  }

  .modal-header {
    border-bottom: .063rem solid #eaebef;
    padding: 1.2rem;
    .modal-title {
      font: normal normal 24px/28px Helvetica;
    }
  }
  .modal-body {
    padding: 1.2rem;
  }

  .modal-footer {
    border-top: .063rem solid #eaebef;
  }

  .view-log-list {
    li {
      font: normal 600 14px/28px Helvetica;
      span {
        font-weight: normal;
        margin-left: 5px;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: block;
}
