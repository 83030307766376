.react-progress {
	background-color: #E8E0FE;
	border-radius: 2px;
	position: relative;
	margin: 15px 0;
	height: 24px;
	width: 100%;
	.progress-done {
		background: #C2ADFA;
		border-radius: 2px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		width: 0;
		opacity: 0;
		transition: 1s ease 0.3s;
	}
	.progress-text {
		font: normal bold 10px/16px Helvetica;
		letter-spacing: 1.5px;
		text-transform: uppercase;
    padding: 0 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.react-progress-time {
	background: transparent;
	margin: 0 0 2px;
	border-radius: 0;
	.progress-done {
		background: rgba(133, 92, 248, 1);
	}
	.progress-text {
		font: normal bold 12px/16px Helvetica;
		text-transform: capitalize;
		letter-spacing: 0.4px;
		text-align: right;
		flex: 1;
	}
}

.react-progress-location {
	position: relative;
	.progress-text {
		position: absolute;
		top: 5px;
		left: 0;
		color: #fff;
	}
}