.react-switch-wrap {
	display: flex;
	.react-switch-checkbox {
		height: 0;
		width: 0;
		visibility: hidden;
	}
	.react-switch-label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 52px;
		height: 26px;
		background: #C9CAD0;
		border-radius: 100px;
		position: relative;
		transition: background-color 0.2s;
		.react-switch-button {
			content: '';
			position: absolute;
			top: 3px;
			left: 3px;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			transition: 0.6s;
			background: #13171F;
			box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
		}
		&:active {
			.react-switch-button {
				width: 60px;
			}
		}
	}
	.react-switch-checkbox {
		&:checked {
			+ {
				.react-switch-label {
					.react-switch-button {
						left: calc(100% - 3px);
						transform: translateX(-100%);
						background: #fff;
					}
				}
			}
		}
	}
}