.common-slide-preview {
	background: rgba(78, 146, 192, 0.9);
  padding: 30px;
  max-width: 404px;
  h2 {
    font: normal bold 20px/26px Helvetica;
    color: #fff;
  }
  p {
    font: normal normal 14px/21px Helvetica;
    color: #fff;
  }
}

.intro-slide-preview {
  text-align: center;
  h2 {
    margin: 0 0 20px;
  }
  .announcement-icon {
    width: 48px;
    margin: 0 0 15px;
  }
}

.inner-slide-preview {
  display: flex;
  align-items: center;
  .img-wrap {
    width: 114px;
    height: 167px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .info-wrap {
    flex: 1;
    h2 {
      margin: 0 0 15px;
    }
  }
}
