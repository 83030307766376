html,
body {
	font-size: 100%;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	background: $body-bg-color;
	color: $body-text-color;
	font-size: $font-size;
	font-family: $font-family--primary;
	text-rendering: optimizeLegibility;
	height: 100%;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: Helvetica;
}

p {
	margin: 0;
}

a {
	color: #6f75bc;
	outline: none;
	text-decoration: none;
	font-family: Helvetica;
}

.form-control,
button {
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.gap-30 {
	gap: 30px;
}

.pr-60 {
	padding-right: 60px !important;
}

.main-wrapper {
	display: flex;
	flex-wrap: nowrap;
	height: calc(100vh - 80px);
	max-height: calc(100vh - 80px);
	overflow-x: auto;
	overflow-y: hidden;
	margin: 80px 0 0;
}

.main-content {
	padding: 0 24px 40px;
	overflow-y: auto;
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
		background-color: #13171f;
		border-radius: 4px;
	}
	&::-webkit-scrollbar {
		width: 8px;
		height: 6px;
		background-color: #13171f;
		border-radius: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #fff;
		border: 1px solid #13171f;
		border-radius: 4px;
	}
}

.row.equal-height-row {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	min-height: 100vh;
}

.row.equal-height-row > [class*='col-'] {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
}

textarea {
	resize: none;
}

.scrollbar-wrap {
	min-height: 60px;
	max-height: 60px;
	overflow-y: auto;
}

.scrollbar-wrap::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
	background: #ededed;
}

.scrollbar-wrap::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: #d3d3d3;
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

input:disabled,
button:disabled,
a[disabled],
.disabled {
	opacity: 0.65;
	pointer-events: none;
	cursor: default;
}

.disabled {
	background: #e2e5ea !important;
	svg {
		filter: grayscale(1);
	}
}

.common-tickbox {
	display: flex;
	align-items: center;
	margin-right: 15px;
	input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
		&:checked {
			+ {
				label {
					&:before {
						background: #6f75bc;
					}
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 2px;
						left: 6px;
						width: 6px;
						height: 12px;
						border: solid #fff;
						border-width: 0 2px 2px 0;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	label {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 12px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #6f75bc;
		&.thik-border {
			&:before {
				border: 2px solid #707070;
			}
		}
		&:before {
			content: '';
			-webkit-appearance: none;
			background-color: transparent;
			border: 1px solid #707070;
			border-radius: 2px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
				inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
			padding: 8px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 5px;
		}
		img {
			margin-left: 0 !important;
		}
	}
}

.custom-progress-wrap {
	margin-bottom: 20px;
	max-width: 140px;
	&:last-child {
		margin-bottom: 0;
	}
	.progress-holder {
		display: flex;
		width: 100%;
		align-items: center;
		.common-progress {
			flex: 1;
			margin: 0;
			height: 12px;
			background: #e0e0e3;
			border-radius: 6px;
			width: 83px;
		}
		.progress-value {
			margin-right: 18px;
			font: normal normal 18px/21px Helvetica;
			letter-spacing: normal;
			text-align: left;
			color: #1b1c21;
		}
	}
	.progress-info {
		margin-top: 5px;
		display: block;
		font-size: 10px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: left;
		color: #404244;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
.progress-bar-primary {
	background: #72a6fd;
}

.progress-bar-success {
	background: #62ceb5;
}

.progress-bar-warning {
	background: #f8c068;
}

.progress-bar-danger {
	background: #f88968;
}

.common-label {
	max-width: 140px;
	padding: 8px 10px;
	font: normal normal 16px/18px Helvetica;
	border-radius: 4px;
	text-align: center;
	&.bg-completed {
		color: #539a86;
		background: #dcf4ee;
	}
	&.bg-inprogress {
		background: #f6d6ca;
		color: #fe6132;
	}
	&.bg-not-started {
		background: #fca1a1;
		color: #770202;
	}
}

.common-btn {
	padding: 12px 24px;
	font: normal normal 18px/21px Helvetica;
	margin: 4px 0;
	border: 1px solid transparent;
	border-radius: 6px;
	@media (max-width: 767px) {
		font-size: 14px;
	}

	&.btn-border-color {
		border-color: #13171f;
		color: #13171f;
		background: transparent;
	}

	&.btn-bg-green-color {
		background: #99dbc1;
		border-color: #99dbc1;
		color: #13171f;
	}
	&.btn-bg-primary-color {
		background: #454b93;
		border-color: #454b93;
		color: #fff;
	}
	&.btn-bg-secondary-color {
		background: #7241f0;
		border-color: #7241f0;
		color: #fff;
	}
}

.loader-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.common-box {
	background: #fff;
	border-radius: 8px;
	padding: 20px;
	margin: 0 0 30px;
}

.common-circle-wrap {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bg-primary-color {
	background: #6877c6;
}

.bg-info-color {
	background: #7295c4;
}

.bg-secondary-color {
	background: #72b4c4;
}

.bg-success-color {
	background: #78b4a4;
}

.react-confirm-alert-body {
	font: normal normal 18px/21px Helvetica !important;
	text-align: center !important;
	.react-confirm-alert-button-group {
		justify-content: center;
		button {
			background: #13171f;
			&:hover {
				background: #363a41;
			}
		}
	}
}

.react-confirm-alert-overlay {
	z-index: 9999 !important;
	background: rgba(0, 0, 0, 0.9) !important;
}

.no-learners-found {
	display: block;
	padding: 40px 0;
	text-align: center;
	font: normal normal 18px/21px Helvetica;
	color: #6d6d71;
}

.common-floating-form {
	padding: 0 !important;
	min-height: auto !important;
	.form-floating {
		.form-control {
			&:focus {
				color: #13171f !important;
				border: 1px solid #6f75bc !important;
				box-shadow: none !important;
			}
		}
		> label {
			font: normal normal 16px/30px Helvetica !important;
			color: #4e545f !important;
		}
	}
}
