.login-wrapper {
	background: linear-gradient(
		360deg,
		#7241f0 0.92%,
		rgba(196, 196, 196, 0) 100%
	);
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 40px 0;
}

.login-form {
	max-width: 634px;
	min-height: auto;
	border-radius: 8px;
	background: #fff;
	padding: 60px;
	@media (max-width: 425px) {
		padding: 30px;
	}
	.form-floating {
		.form-control {
			padding-right: 60px;
		}
	}
	.error-msg {
		margin-top: 18px;
	}
}

.login-logo-wrap {
	width: auto;
	height: 74px;
	margin: 0 0 37px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.login-info-wrap {
	margin: 0 0 37px;
	h4 {
		margin: 0 0 6px;
		font: normal normal 24px/28px Helvetica;
		color: #1b1c21;
	}
	span {
		display: block;
		font: normal normal 16px/18px Helvetica;
		color: #9298a3;
	}
}

.login-show-password {
	position: absolute;
	top: 0;
	right: 0;
	padding: 19px;
	cursor: pointer;
	img {
		width: 25px;
	}
}

.login-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media(max-width: 375px) {
		flex-wrap: wrap;
	}
	.forget-password {
		color: #7241f0;
		font: normal normal 18px/21px Helvetica;
		text-decoration-line: underline;
	}
	.common-btn {
		display: flex;
		align-items: center;
		padding: 12px 30px;
		@media(max-width: 575px) {
			padding: 12px 15px;
		}
	}
	.btn-border-color {
		background: transparent;
	}
	.back-icon {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
	.token-icon {
		width: 20px;
		height: 20px;
		margin-left: 8px;
	}
}

.reset-token-text {
	display: block;
	font: normal normal 16px/18px Helvetica;
	color: #9298a3;
	&.password-error-msg {
		color: #f46582;
	}
}

.success-img-wrap {
	margin: 0 0 37px;
	width: 49px;
	height: 71px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}