.modal-content-sidebar {
	max-width: 720px;
	min-width: 720px;
	transform: translateX(100%);
	position: fixed;
	z-index: 1032;
	height: auto;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: #fff;
	overflow-x: hidden;
	transition: 0.5s;
	padding: 100px 55px 80px;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
	@media (max-width: 767px) {
		max-width: 100%;
		min-width: 100%;
		padding: 40px;
	}
	&.active {
		transform: translateX(0);
	}
	.overview-table-header {
		margin: 0 0 70px;
		h4 {
			font-size: 24px;
			color: #1b1c21;
		}
	}
}
.modal-import-learner {
	position: relative;
	padding-bottom: 100px;
	min-height: 70vh;
	p {
		color: #6d6d71;
		margin: 0 0 20px;
		font: normal normal 16px/18px Helvetica;
	}
}
.modal-btn-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	bottom: 0;
	right: 0;
	span {
		font: normal normal 18px/21px Helvetica;
		margin-right: 35px;
		color: #13171f;
		cursor: pointer;
		@media (max-width: 767px) {
			margin-right: 15px;
		}
	}
	.common-btn {
		@media (max-width: 767px) {
			padding: 12px;
		}
	}
}

.floating-form {
	position: relative;
	padding-bottom: 100px;
	min-height: 70vh;
	.form-floating {
		.form-control {
			font: normal normal 18px/21px Helvetica;
			border-radius: 6px;
			border: 1px solid rgba(111, 117, 188, 0.2);
			&:focus {
				color: #13171f;
				border: 2px solid #6f75bc;
				box-shadow: 0px 0 0 8px #eaebf5;
			}
		}
		> label {
			color: #9b9ba2;
			font: normal normal 18px/30px Helvetica;
		}
		.form-control.is-invalid:not(:placeholder-shown) ~ label {
			color: #4e545f;
		}
		.form-control:not(:placeholder-shown) ~ label {
			color: #4e545f;
		}
		.form-control:not(:placeholder-shown) {
			padding-top: 2.025rem;
		}

		.form-control:focus ~ label,
		.form-select ~ label {
			color: #6f75bc;
			opacity: 1;
		}
		.form-control,
		.form-floating > .form-select {
			height: calc(3.5rem + 10px);
		}
		.form-control.is-invalid:focus ~ label,
		.form-select ~ label {
			color: #f46582;
			opacity: 1;
		}
		.form-control.is-invalid:focus,
		.was-validated .form-control:invalid:focus {
			border-color: #f46582;
			box-shadow: 0px 0 0 8px #fff7f8;
		}
		.was-validated .form-control:invalid,
		.form-control.is-invalid {
			background-image: none;
		}
		.textarea-control {
			min-height: 95px;
		}
	}
	.error-msg {
		display: flex;
		align-items: center;
		margin-top: 15px;
		span {
			color: #f46582;
			font: normal normal 16px/18px Helvetica;
			margin-left: 8px;
		}
	}

	.form-check-label {
		font: normal normal 16px/18px Helvetica;
		margin-bottom: 10px;
		color: #13171f;
	}
}

.upload-file-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: #fff;
	border: 1px solid #cdd3de;
	padding: 10px 20px;
	border-radius: 8px;
	margin-top: 40px;
	p {
		font: normal normal 18px/21px Helvetica;
		margin: 0;
		color: #9b9ba2;
		max-width: 100%;
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.fileUpload {
		background: #f2f3ff;
		border-radius: 24px;
		color: #6f75bc;
		overflow: hidden;
		padding: 8px 24px 10px;
		position: relative;
		text-align: center;
		cursor: pointer;
		span {
			font: normal normal 16px/18px Helvetica;
		}
		&:hover {
			background: #f2f3ff;
			cursor: pointer;
		}
		&:active {
			background: #f2f3ff;
			cursor: pointer;
		}
		&:focus {
			background: #f2f3ff;
			cursor: pointer;
		}
		input.upload {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			padding: 0;
			font-size: 0;
			cursor: pointer;
			opacity: 0;
			filter: alpha(opacity=0);
			width: 148px;
			height: 46px;
			cursor: pointer;
		}
	}
	input[type='file'] {
		position: fixed;
		right: 100%;
		bottom: 100%;
	}
}

.upload-img-wrap {
	width: 44px;
	height: 44px;
	border-radius: 4px;
	overflow: hidden;
	background: #7241f0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.upload-img-holder {
	display: flex;
	align-items: center;
	flex: 1;
	.profile-delete-btn {
		background: transparent;
		border: none;
		padding: 0;
		margin-left: 10px;
	}
}
