.main-navigation {
	height: 80px;
	padding: 20px 0;
	background: #fff;
	@media (max-width: 991px) {
		height: auto;
	}
	.container-fluid {
		padding: 0 24px;
	}
	.navbar-brand {
		height: 32px;
		padding: 0;
		img {
			width: auto;
			height: 100%;
			object-fit: contain;
		}
	}

	.navbar-right-nav {
		@media (max-width: 991px) {
			border-top: 2px solid #eaebef;
			margin-top: 20px;
			padding-top: 20px;
		}
		.nav-item {
			display: flex;
			align-items: center;
			margin-right: 40px;
			&:last-child {
				margin-right: 0;
				margin-bottom: 0;
			}
			@media (max-width: 991px) {
				margin: 0 0 20px;
			}
		}
		.nav-item-view {
			border-right: 2px solid #eaebef;
			padding-right: 40px;
			@media (max-width: 991px) {
				border: none;
				padding-right: 0;
			}
			.nav-link {
				color: #6f75bc;
				&:hover {
					color: #6f75bc;
				}
				img {
					margin-left: 18px;
				}
			}
		}
		.nav-item-help {
			img {
				margin-right: 16px;
			}
		}
		.nav-link {
			display: flex;
			align-items: center;
			padding: 0;
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #a4a7ad;
			@media(max-width: 991px) {
				font-size: 16px;
			}
			&:hover {
				color: #a4a7ad;
			}
			img {
				width: 18px;
				height: 18px;
			}
		}
		.user-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #7241f0;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #c7b1de;
			width: 56px;
			height: 56px;
			padding: 12px;
			border-radius: 50%;
			@media(max-width: 991px) {
				font-size: 20px;
			}
		}
	}
	.sm-show {
		display: none;
		@media (max-width: 991px) {
			display: block;
			margin-left: 16px;
		}
	}
}
