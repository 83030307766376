.edit-content-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 15px;
	h2 {
		font: normal bold 24px/28px Helvetica;
	}
	.common-btn {
		font: normal normal 16px/18px Helvetica;
		padding: 8px 30px;
	}
}

.url-text {
	padding-right: 60px !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #6f75bc;
	text-decoration: underline;
	&:hover {
		color: rgba(111, 117, 188, 0.9);
	}
}

.mr-5 {
	margin-right: 5px;
}

.lerners-info-edit-title {
	display: flex;
	gap: 0.6rem;
	img {
		cursor: pointer;
	}
}
