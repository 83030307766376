.section-progress-table {
	.table {
		tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
			margin: 0 0 20px;
		}
	}
}
