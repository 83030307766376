.content-overview-list {
	background: #fff;
	border-radius: 4px;
	width: 100%;
	overflow: hidden;
	.img-wrap {
		width: 100%;
		height: 181px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	h4 {
		font: normal normal 16px/18px Helvetica;
		color: #13171f;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		min-height: 36px;
	}

	.common-btn {
		font: normal normal 12px/14px Helvetica;
		margin: 0;
		padding: 9px 14px;
	}
	.info-wrap {
		padding: 16px 18px;
	}

	.btn-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 16px 18px;
	}
}
