.ReactFlagsSelect-module_selectBtn__19wW7 {
	border: none !important;
	padding: 0 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
	max-width: 180px;
	min-width: 180px;
	right: 0 !important;
	left: inherit !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
	background-image: url('/assets/images/common/drop-arrow.svg');
	background-repeat: no-repeat;
	border: none !important;
	width: 17px !important;
	height: 8px !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
	transform: rotate(180deg);
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
	border: 1px solid rgba(111, 117, 188, 0.2);
	border-radius: 6px;
}

.ReactFlagsSelect-module_label__27pw9, .ReactFlagsSelect-module_secondaryLabel__37t1D {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	color: #13171F;
}