.page-not-found-wrapper {
	background: linear-gradient(
		360deg,
		#7241f0 0.92%,
		rgba(196, 196, 196, 0) 100%
	);
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 40px 0;
}

.page-not-found {
	position: absolute;
	width: 100vw;
	height: 80vh;
	max-width: 100vmin;
	max-height: 80vmin;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	.page-not-found-info {
		position: absolute;
		top: 12%;
		left: 3%;
		text-align: center;
		line-height: 2em;
		font-size: 24px;
		color: #5d7399;
		font-family: Dosis;
		a {
			font-size: 18px;
			font-weight: 400;
			color: inherit;
			text-decoration: none;
			opacity: 0.6;
			border-bottom: 1px dashed rgba(93, 115, 153, 0.5);
		}
	}
}
