.sidebar-holder {
	width: 260px;
	background: #13171f;
	transition: all 0.35s ease;
}
.sidebar {
	width: 260px;
	height: 100%;
	position: relative;
	padding: 30px 8px 20px;
	.nav-link:hover,
	.active > .nav-link,
	.active > .active {
		background: #363a41;
		color: #dfe3ea;
		svg {
			path {
				stroke: #dfe3ea;
			}
		}
	}
}
.sidebar-nav-list {
	.nav-link {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	li {
		margin: 0 0 10px;
		&.active {
			.sideabar-side-nav {
				display: block;
			}
		}
		.nav-link {
			display: flex;
			align-items: center;
			font: normal normal 18px/21px Helvetica;
			letter-spacing: normal;
			text-align: left;
			color: #a3a6ac;
			padding: 15px;
			cursor: pointer;
			&.active {
				background: #363a41;
			}
		}
	}
	.icon-wrap {
		display: flex;
		align-items: center;
		svg {
			width: 18px;
			height: 18px;
		}
		.sidebar-link-text {
			margin-left: 20px;
		}
	}
}

.sideabar-side-nav {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	width: 196px;
	background: #21252c;
	display: none;
	overflow-y: auto;
	.back-nav {
		display: flex;
		margin: 0 0 15px;
		padding: 30px 15px;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 23px;
		color: #dfe3ea;
		border-bottom: 1px solid #3b3f45;
		cursor: pointer;
		img {
			margin-right: 16px;
		}
	}
	ul {
		padding: 0 8px;
	}
}

.dropnav-active {
	width: 64px;
	.sidebar-link-text {
		display: none;
	}
	.nav-link {
		.arrow-icon {
			display: none;
		}
	}
}

.sidebar-settting {
	display: flex;
	align-items: center;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #a3a6ac;
	padding: 15px;
	border-radius: 0.25rem;
	cursor: pointer;
	&:hover,
	&.active {
		background: #363a41;
		color: #dfe3ea;
		svg {
			path {
				stroke: #dfe3ea;
			}
		}
	}
	.icon-wrap {
		display: flex;
		align-items: center;
		svg {
			width: 18px;
			height: 18px;
		}
		.sidebar-link-text {
			margin-left: 20px;
		}
	}
}
