.common-overview-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	margin: 0 -24px 20px;
	padding: 10px 24px;
	.lerners-info {
		margin: 4px 8px 4px 0;
		h2 {
			font: normal normal 24px/28px Helvetica;
			color: #1b1c21;
			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
		span {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 18px;
			color: #9298a3;
			@media (max-width: 767px) {
				font-size: 14px;
			}
		}
	}
	.search-wrap {
		margin: 4px 0;
		max-width: 400px;
		border: 2px solid #f5f5f6;
		background: #fff;
		border-radius: 6px;
		height: 50px;
		.input-group-text {
			background: transparent;
			border: none;
			padding: 6px 12px 6px 20px;
			img {
				width: 19px;
				height: 19px;
			}
		}
		.form-control {
			background: transparent;
			border: none;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			color: #13171f;
			padding: 6px 20px 6px 0;
			@media (max-width: 767px) {
				font-size: 14px;
			}
			&::placeholder {
				color: #9298a3;
			}
		}
	}
	.button-wrap {
		margin: 4px 0;
		display: flex;
		flex-wrap: wrap;
		.common-btn {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.overview-table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 22px;
	h4 {
		font: normal 300 20px/23px Helvetica;
		color: #6b7079;
		margin: 4px 8px 4px 0;
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
	.overview-back {
		display: flex;
		align-items: center;
		color: #1b1c21;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 18px;
		margin: 4px 0;
		cursor: pointer;
		@media (max-width: 767px) {
			font-size: 14px;
		}
		img {
			margin: 0 12px 0 0;
		}
	}
}

.overview-table-block {
	background: #fff;
	padding: 24px 30px;
	border-radius: 4px;
	position: relative;
	margin-bottom: 25px;
	@media (max-width: 1799px) {
		padding: 20px;
	}
}

.overview-table-list {
	margin: 0 -30px;
	border: none;
	.overview-thead-showing {
		tr {
			td {
				padding: 0 30px;
			}
		}
	}
	@media (max-width: 1799px) {
		margin: 0 -20px;
	}
	&.selected {
		.overview-thead-showing {
			display: none;
		}
		.overview-thead-hidden {
			display: table-header-group;
		}
		.bg-selected-color {
			background: #f7f8fa;
		}
	}
	.overview-thead-hidden {
		display: none;
		tr {
			td {
				padding-top: 0;
			}
		}
	}
	.table {
		table-layout: fixed;
		margin: 0;
		tr {
			vertical-align: middle;
			@media (max-width: 1299px) {
				display: block;
				border-top: 1px solid #dedede;
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
	tbody {
		tr {
			border-bottom: 2px solid #fff;
			&.active {
				background: #f7f8fa;
			}
		}
	}
	thead {
		th {
			border: none;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #242424;
			position: relative;
			@media (max-width: 855px) {
				display: block;
			}
			&:first-child {
				&:after {
					display: none;
				}
			}
			&:after {
				content: '';
				position: absolute;
				width: 0px;
				height: 27px;
				left: 0;
				top: 50%;
				border: 2px solid #eaebef;
				border-radius: 50px;
				transform: translateY(-50%);
				@media (max-width: 855px) {
					display: none;
				}
			}
		}
		.thead-text {
			display: flex;
			align-items: center;
			font: normal normal 16px/18px Helvetica;
			color: #a4a4a6;
			@media (max-width: 1199px) {
				font-size: 14px;
			}
			img {
				margin-left: 24px;
			}
		}
		.thead-img-wrap {
			width: 100%;
			text-align: center;
			@media (max-width: 1199px) {
				font-size: 14px;
				text-align: left;
				display: flex;
				align-items: center;
			}
		}
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
		background-color: #252525;
	}
	&::-webkit-scrollbar {
		width: 1px;
		height: 6px;
		background-color: #252525;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #fff;
		border: 1px solid #252525;
	}
	td,
	th {
		padding: 15px 30px;
		border: none;
	}
	td {
		padding-top: 20px;
		@media (max-width: 1299px) {
			display: block;
			padding: 10px 30px !important;
		}
	}
	.info-wrap {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		color: #1b1c21;
		font: normal normal 18px/21px Helvetica;
		@media (max-width: 1799px) {
			font-size: 14px;
		}
		.img-wrap {
			width: 56px;
			height: 56px;
			background: #6877c6;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 24px;
			@media (max-width: 1799px) {
				width: 34px;
				height: 34px;
				margin-right: 8px;
			}
			@media (max-width: 1299px) {
				width: 40px;
				height: 40px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		span {
			margin-right: 24px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
			@media (max-width: 1799px) {
				margin-right: 8px;
			}
		}

		.new-label {
			display: inline-block;
			vertical-align: middle;
			color: #397ff5;
			background: #ebf3ff;
			font-size: 14px;
			line-height: 16px;
			border-radius: 20px;
			padding: 8px 20px 10px;
			@media (max-width: 1799px) {
				padding: 4px 8px 6px;
				font-size: 12px;
			}
		}
		.common-tickbox {
			margin-right: 10px;
		}
		&.view-learner-text {
			span {
				max-width: 100%;
				margin: 0;
			}
		}
		.login-history-wrap {
			span {
				margin-right: 24px;
			}
		}
	}
	.word-break {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		text-align: left;
		color: #242424;
		text-decoration: none;
	}
	.custom-progress-wrap {
		@media (max-width: 1299px) {
			max-width: 100%;
		}
		.progress-holder {
			.common-progress {
				@media (max-width: 1299px) {
					height: 8px;
				}
			}
			.progress-value {
				@media (max-width: 1799px) {
					font-size: 14px;
				}
			}
		}
	}
	.common-label {
		@media (max-width: 1799px) {
			font-size: 14px;
		}
	}
}

.overview-tab-holder {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 30px;
	@media (max-width: 1199px) {
		margin: 0 0 15px;
	}
}

.show-filters {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	color: #1b1c21;
	@media (max-width: 1199px) {
		font-size: 14px;
	}
	@media (max-width: 767px) {
		margin: 0 0 15px;
	}
	&:hover {
		color: #1b1c21;
	}
	img {
		margin-right: 12px;
	}
}

.common-nav-tabs {
	border: none;
	li {
		margin: 4px 45px 4px 0;
		@media (max-width: 767px) {
			margin: 4px 15px 15px 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.nav-link {
		padding: 0 2px 6px;
		border-bottom: 4px solid transparent;
		border-top: none;
		border-left: none;
		border-right: none;
		font: normal normal 18px/21px Helvetica;
		color: #6d6d71;
		@media (max-width: 1199px) {
			font-size: 16px;
		}
		&.active,
		&:hover {
			border-color: #6f75bc;
			color: #1b1c21;
		}
	}
}

.overview-table-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
	.row-page-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		h4 {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 18px;
			color: #a4a4a6;
			margin: 4px 20px 4px 0;
		}
		.form-select {
			min-width: 80px;
			flex: 1;
			margin: 4px 0;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			color: #1b1c21;
			border: 1px solid #eaebef;
			background-image: url('../../assets/images/common/arrow-down-blue-icon.svg');
			&:focus {
				box-shadow: none;
			}
		}
	}
	&.fixed-bottom {
		background: #f2f4f7;
		left: inherit;
		width: calc(100% - 260px);
		padding: 4px 24px;
		@media(max-width: 767px) {
			all: unset;
		}
	}
}
.custom-pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 4px 0;
	.pagination-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 4px 0;
		a {
			padding: 12px 14px;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px;
			&[disabled] {
				background: #e2e5ea;
			}
		}
		.form-control {
			margin: 0 12px;
			padding: 10px 6px;
			background: #fff;
			max-width: 80px;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			color: #1b1c21;
			text-align: center;
			border-radius: 3px;
			border: 1px solid #eaebef;
			&:focus {
				box-shadow: none;
			}
		}
	}
	.showing-page {
		margin-left: 16px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 18px;
		color: #a4a4a6;
	}
}

.login-history-table {
	.table {
		tr {
			@media (max-width: 1299px) {
				display: revert;
			}
			th,
			td {
				@media (max-width: 1299px) {
					display: revert;
				}
				@media (max-width: 855px) {
					display: block;
				}
			}
		}
	}
}

.thead-view-learner {
	display: flex;
	align-items: center;
	.common-tickbox {
		input {
			&:checked {
				+ {
					label {
						&:before {
							background: #fff;
						}
						&:after {
							width: 0;
							transform: rotate(90deg);
							top: 4px;
							left: 9px;
							border-color: #707070;
						}
					}
				}
			}
		}
	}
}

.view-learner-list {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background: #f7f8fa;
	padding: 10px 26px;
	border-radius: 8px;
	li {
		position: relative;
		margin: 4px 30px 4px 0px;
		padding: 0 30px 0 0;
		@media (max-width: 767px) {
			margin-right: 15px;
			padding: 0;
		}
		a,
		span {
			font: normal normal 16px/18px Helvetica;
			color: #1b1c21;
			display: flex;
			align-items: center;
			cursor: pointer;
			@media (max-width: 1199px) {
				font-size: 14px;
			}
			img {
				height: 24px;
				width: 24px;
				margin-right: 12px;
			}
		}
		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 27px;
			right: 0;
			top: 50%;
			border: 2px solid #dbe0e6;
			border-radius: 50px;
			transform: translateY(-50%);
			@media (max-width: 767px) {
				display: none;
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

.inner-table-footer {
	background: #f2f4f7;
	margin: 0 -20px -20px;
	padding: 20px 20px 0;
}
